<template>
    <div class="home">
        <div class="header-banner">
            <img class="img" src="@/assets/home/melves_banner.jpg" />
        </div>
        <div class="header">
            <div class="block-box">
                <div>正常</div>
                <div class="num">{{count.online}}</div>
            </div>
            <div class="block-box">
                <div>离线</div>
                <div class="num">{{count.offline}}</div>
            </div>
            <div class="block-box">
                <div>告警</div>
                <div class="num">{{count.alarm}}</div>
            </div>
        </div>
        <div class="body">
            <!-- 新版 -->
            <div v-if="melvesVesion === 'M2'">
                <div v-if="devList.length < 3">
                    <div :class="{'block-inline-row':true,'online':item.devStatus ==='online','alarm':item.devStatus === 'alarm'}" 
                        v-for="(item,index) in devList" :key="index" @click="goDetails(item)">
                        <div class="top">
                            <div class="name">{{item.devName}}</div>
                            <div class="name">{{item.devCode}}</div>
                        </div>
                        <div class="middle">
                            <div><span>空气温度：</span>{{item.melvesStatus ? item.melvesStatus.airTemp : '0.00'}}℃</div>
                            <div><span>空气湿度：</span>{{item.melvesStatus ?item.melvesStatus.airHumi : '0'}}%</div>
                            <div><span>能见度：</span>{{item.melvesStatus ? item.melvesStatus.visibility : '10000'}}m</div>
                            <div class="flex-algin-center"><span>路面状态：</span>
                                {{ item.melvesStatus && item.melvesStatus.roadState == 1 ? 
                                '潮湿' : item.melvesStatus && item.melvesStatus.roadState == 2 ? 
                                '积水' : item.melvesStatus && item.melvesStatus.roadState == 3 ? 
                                '积雪' : item.melvesStatus && item.melvesStatus.roadState == 4 ? 
                                '结冰' :'干燥'}}
                                <span v-if="(item.melvesStatus && item.melvesStatus.roadState == 1) || (item.melvesStatus && item.melvesStatus.roadState == 2)" class="road-tag-mild"></span>
                                <span v-if="(item.melvesStatus && item.melvesStatus.roadState == 3) || (item.melvesStatus && item.melvesStatus.roadState == 4)" class="road-tag-serious"></span>
                            </div>
                            <div v-if="item.melvesStatus &&item.melvesStatus.roadState >= 2 ">
                                <span>积水结冰厚度：</span>{{item.melvesStatus && item.melvesStatus.coverThickness ? item.melvesStatus.coverThickness : 0}} mm
                            </div>
                            <div>
                                <span>路面温度：</span>
                                {{ !item.melvesStatus ? '0.00℃' 
                                : item.melvesStatus.roadbedTemp === -273 ? '- -' : `${item.melvesStatus.roadbedTemp}℃` }}
                            </div>
                            <div>
                                <span>路下10cm温度：</span>
                                {{ !item.melvesStatus ? '0.00℃' 
                                : item.melvesStatus.road10cmTemp === -273 ? '- -' : `${item.melvesStatus.road10cmTemp}℃` }}
                            </div>
                        </div>
                        <div class="bottom">
                            <div class="time">{{item.melvesStatus ?item.melvesStatus.recordTime : '0000-00-00 00:00:00'}}</div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="block-line-part" v-for="(item,index) in devList" :key="index">
                        <div class="position-area" v-if="index % 2 == 0"></div>
                        <div class="inner-area" v-else>
                            <div :class="{'box-info':true,'online':item.devStatus ==='online','alarm':item.devStatus === 'alarm'}" @click="goDetails(item)">
                                <div class="name">{{item.devName}}</div>
                                <div class="name">{{item.devCode}}</div>
                                <div><span>空气温度：</span>{{item.melvesStatus ? item.melvesStatus.airTemp : '0.00'}}℃</div>
                                <div><span>空气湿度：</span>{{item.melvesStatus ?item.melvesStatus.airHumi : '0'}}%</div>
                                <div><span>能见度：</span>{{item.melvesStatus ? item.melvesStatus.visibility : '10000'}}m</div>
                                <div class="flex-algin-center"><span>路面状态：</span>
                                    {{item.melvesStatus && item.melvesStatus.roadState == 1 ? 
                                    '潮湿' : item.melvesStatus && item.melvesStatus.roadState == 2 ? 
                                    '积水' : item.melvesStatus && item.melvesStatus.roadState == 3 ? 
                                    '积雪' : item.melvesStatus && item.melvesStatus.roadState == 4 ? 
                                    '结冰' :'干燥'}}
                                    <span v-if="(item.melvesStatus && item.melvesStatus.roadState == 1) || (item.melvesStatus && item.melvesStatus.roadState == 2)" class="road-tag-mild"></span>
                                    <span v-if="(item.melvesStatus && item.melvesStatus.roadState == 3) || (item.melvesStatus && item.melvesStatus.roadState == 4)" class="road-tag-serious"></span>
                                </div>
                                <div v-if="item.melvesStatus &&item.melvesStatus.roadState >= 2 ">
                                    <span>积水结冰厚度：</span>{{item.melvesStatus && item.melvesStatus.coverThickness ? item.melvesStatus.coverThickness : 0}} mm
                                </div>
                                <div>
                                    <span>路面温度：</span>
                                    {{ !item.melvesStatus ? '0.00℃' 
                                    : item.melvesStatus.roadbedTemp === -273 ? '- -' : `${item.melvesStatus.roadbedTemp}℃` }}
                                </div>
                                <div>
                                    <span>路下10cm温度：</span>
                                    {{ !item.melvesStatus ? '0.00℃' 
                                    : item.melvesStatus.road10cmTemp === -273 ? '- -' : `${item.melvesStatus.road10cmTemp}℃` }}
                                </div>
                                <div class="time">{{item.melvesStatus ?item.melvesStatus.recordTime : '0000-00-00 00:00:00'}}</div>
                            </div>
                        </div>
                        <div class="line-area"></div>
                        <div class="inner-area"  v-if="index % 2 == 0">
                            <div :class="{'box-info':true,'online':item.devStatus ==='online','alarm':item.devStatus === 'alarm'}"  @click="goDetails(item)">
                                <div class="name">{{item.devName}}</div>
                                <div class="name">{{item.devCode}}</div>
                                <div><span>空气温度：</span>{{item.melvesStatus ? item.melvesStatus.airTemp : '0.00'}}℃</div>
                                <div><span>空气湿度：</span>{{item.melvesStatus ?item.melvesStatus.airHumi : '0'}}%</div>
                                <div><span>能见度：</span>{{item.melvesStatus ? item.melvesStatus.visibility : '10000'}}m</div>
                                <div class="flex-algin-center"><span>路面状态：</span>
                                    {{ item.melvesStatus && item.melvesStatus.roadState == 1 ? 
                                    '潮湿' : item.melvesStatus && item.melvesStatus.roadState == 2 ? 
                                    '积水' : item.melvesStatus && item.melvesStatus.roadState == 3 ? 
                                    '积雪' : item.melvesStatus && item.melvesStatus.roadState == 4 ? 
                                    '结冰' :'干燥'}}
                                    <span v-if="(item.melvesStatus && item.melvesStatus.roadState == 1) || (item.melvesStatus && item.melvesStatus.roadState == 2)" class="road-tag-mild"></span>
                                    <span v-if="(item.melvesStatus && item.melvesStatus.roadState == 3) || (item.melvesStatus && item.melvesStatus.roadState == 4)" class="road-tag-serious"></span>
                                </div>
                                <div v-if="item.melvesStatus &&item.melvesStatus.roadState >= 2 ">
                                    <span>积水结冰厚度：</span>{{item.melvesStatus && item.melvesStatus.coverThickness ? item.melvesStatus.coverThickness : 0}} mm
                                </div>
                                <div>
                                    <span>路面温度：</span>
                                    {{ !item.melvesStatus ? '0.00℃' 
                                    : item.melvesStatus.roadbedTemp === -273 ? '- -' : `${item.melvesStatus.roadbedTemp}℃` }}
                                </div>
                                <div>
                                    <span>路下10cm温度：</span>
                                    {{ !item.melvesStatus ? '0.00℃' 
                                    : item.melvesStatus.road10cmTemp === -273 ? '- -' : `${item.melvesStatus.road10cmTemp}℃` }}
                                </div>
                                <div class="time">{{item.melvesStatus ?item.melvesStatus.recordTime : '0000-00-00 00:00:00'}}</div>
                            </div>
                        </div>
                        <div class="position-area" v-else></div>
                    </div>
                </div>
            </div>
            <!-- 旧版 -->
            <div v-else>
                <div v-if="devList.length < 3">
                    <div :class="{'block-inline-row':true,'online':item.devStatus ==='online','alarm':item.devStatus === 'alarm'}" 
                        v-for="(item,index) in devList" :key="index">
                        <div class="top">
                            <div class="name">{{item.devName}}</div>
                            <div class="name">{{item.devCode}}</div>
                        </div>
                        <div class="middle">
                            <div><span>空气温度：</span>{{item.roadStatus ? item.roadStatus.airTemperature : '0.00'}}℃</div>
                            <div><span>空气湿度：</span>{{item.roadStatus ?item.roadStatus.airHumidness : '0'}}%</div>
                            <div><span>能见度：</span>{{item.roadStatus ? item.roadStatus.visibility : '10000'}}m</div>
                            <div class="flex-algin-center"><span>路面状态：</span>
                                {{item.roadStatus&&item.roadStatus.waterFreezesType == 1 ? 
                                '潮湿' : item.roadStatus&&item.roadStatus.waterFreezesType == 2 ? 
                                '积水' :item.roadStatus&&item.roadStatus.waterFreezesType == 3 ? 
                                '积雪' : item.roadStatus&&item.roadStatus.waterFreezesType == 4 ? 
                                '结冰' :'干燥'}}
                                <span v-if="(item.roadStatus && item.roadStatus.waterFreezesType == 1) || (item.roadStatus && item.roadStatus.waterFreezesType == 2)" class="road-tag-mild"></span>
                                <span v-if="(item.roadStatus && item.roadStatus.waterFreezesType == 3) || (item.roadStatus && item.roadStatus.waterFreezesType == 4)" class="road-tag-serious"></span>
                            </div>
                            <div v-if="item.roadStatus &&item.roadStatus.waterFreezesType >= 2 ">
                                <span>积水结冰厚度：</span>{{item.roadStatus && item.roadStatus.waterFreezesThickness ? item.roadStatus.waterFreezesThickness.toFixed(2) : 0}} mm
                            </div>
                            <div>
                                <span>路面温度：</span>
                                {{ !item.roadStatus ? '0.00℃' 
                                : item.roadStatus.roadTemperature === -273 ? '- -' : `${item.roadStatus.roadTemperature}℃` }}
                            </div>
                        </div>
                        <div class="bottom">
                            <div class="time">{{item.roadStatus ?item.roadStatus.recordTime : '0000-00-00 00:00:00'}}</div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="block-line-part" v-for="(item,index) in devList" :key="index">
                        <div class="position-area" v-if="index % 2 == 0"></div>
                        <div class="inner-area" v-else>
                            <div :class="{'box-info':true,'online':item.devStatus ==='online','alarm':item.devStatus === 'alarm'}">
                                <div class="name">{{item.devName}}</div>
                                <div class="name">{{item.devCode}}</div>
                                <div><span>空气温度：</span>{{item.roadStatus ? item.roadStatus.airTemperature : '0.00'}}℃</div>
                                <div><span>空气湿度：</span>{{item.roadStatus ?item.roadStatus.airHumidness : '0'}}%</div>
                                <div><span>能见度：</span>{{item.roadStatus ? item.roadStatus.visibility : '10000'}}m</div>
                                <div class="flex-algin-center"><span>路面状态：</span>
                                    {{item.roadStatus && item.roadStatus.waterFreezesType == 1 
                                    ? '潮湿' : item.roadStatus && item.roadStatus.waterFreezesType == 2 
                                    ? '积水' :item.roadStatus && item.roadStatus.waterFreezesType == 3 
                                    ? '积雪' : item.roadStatus && item.roadStatus.waterFreezesType == 4 
                                    ? '结冰' :'干燥'}}
                                    <span v-if="(item.roadStatus && item.roadStatus.waterFreezesType == 1) || (item.roadStatus && item.roadStatus.waterFreezesType == 2)" class="road-tag-mild"></span>
                                    <span v-if="(item.roadStatus && item.roadStatus.waterFreezesType == 3) || (item.roadStatus && item.roadStatus.waterFreezesType == 4)" class="road-tag-serious"></span>
                                </div>
                                <div v-if="item.roadStatus &&item.roadStatus.waterFreezesType >= 2 ">
                                    <span>积水结冰厚度：</span>{{item.roadStatus && item.roadStatus.waterFreezesThickness ? item.roadStatus.waterFreezesThickness.toFixed(2) : 0}} mm
                                </div>
                                <div>
                                    <span>路面温度：</span>
                                    {{ !item.roadStatus ? '0.00℃' 
                                    : item.roadStatus.roadTemperature === -273 ? '- -' : `${item.roadStatus.roadTemperature}℃` }}
                                </div>
                                <div class="time">{{item.roadStatus ?item.roadStatus.recordTime : '0000-00-00 00:00:00'}}</div>
                            </div>
                        </div>
                        <div class="line-area"></div>
                        <div class="inner-area"  v-if="index % 2 == 0">
                            <div :class="{'box-info':true,'online':item.devStatus ==='online','alarm':item.devStatus === 'alarm'}">
                                <div class="name">{{item.devName}}</div>
                                <div class="name">{{item.devCode}}</div>
                                <div><span>空气温度：</span>{{item.roadStatus ? item.roadStatus.airTemperature : '0.00'}}℃</div>
                                <div><span>空气湿度：</span>{{item.roadStatus ?item.roadStatus.airHumidness : '0'}}%</div>
                                <div><span>能见度：</span>{{item.roadStatus ? item.roadStatus.visibility : '10000'}}m</div>
                                <div class="flex-algin-center"><span>路面状态：</span>
                                    {{item.roadStatus && item.roadStatus.waterFreezesType == 1 ?
                                     '潮湿' : item.roadStatus && item.roadStatus.waterFreezesType == 2 ? 
                                     '积水' :item.roadStatus && item.roadStatus.waterFreezesType == 3 ? 
                                     '积雪' : item.roadStatus && item.roadStatus.waterFreezesType == 4 ? 
                                     '结冰' :'干燥'}}
                                    <span v-if="(item.roadStatus && item.roadStatus.waterFreezesType == 1) || (item.roadStatus && item.roadStatus.waterFreezesType == 2)" class="road-tag-mild"></span>
                                    <span v-if="(item.roadStatus && item.roadStatus.waterFreezesType == 3) || (item.roadStatus && item.roadStatus.waterFreezesType == 4)" class="road-tag-serious"></span>
                                </div>
                                <div v-if="item.roadStatus &&item.roadStatus.waterFreezesType >= 2 ">
                                    <span>积水结冰厚度：</span>{{item.roadStatus && item.roadStatus.waterFreezesThickness ? item.roadStatus.waterFreezesThickness.toFixed(2) : 0}} mm
                                </div>
                                <div>
                                    <span>路面温度：</span>
                                    {{ !item.roadStatus ? '0.00℃' 
                                    : item.roadStatus.roadTemperature === -273 ? '- -' : `${item.roadStatus.roadTemperature}℃` }}
                                </div>
                                <div class="time">{{item.roadStatus ?item.roadStatus.recordTime : '0000-00-00 00:00:00'}}</div>
                            </div>
                        </div>
                        <div class="position-area" v-else></div>
                    </div>
                </div>
            </div>
        </div>
        <tabbar />
    </div>
</template>

<script>
import tabbar from '@/components/melves/tabBar.vue'
export default {
    components:{
        tabbar
    },
    data(){
        return{
            devList:[],
            count:{
                online:0,
                offline:0,
                alarm:0,
            },
            melvesVesion:null
        }
    },
    mounted(){
        this.projectCode = window.sessionStorage.getItem('projectCode');
        this.melvesVesion = window.sessionStorage.getItem('melvesVesion');
        this.getCount();
        this.getHomeList();
    },
    methods:{
        getHomeList(){
            this.$api.MELVES.newDevInfo({
                projectCode:this.projectCode
            }).then( d => {
                this.devList = d.reverse();
            })
        },
        getCount(){
            this.$api.MELVES.homeCount({
                projectCode:this.projectCode
            }).then( d => {
                this.count = { ...d };
            })
        },

        goDetails(item){
            window.sessionStorage.setItem('currentMelvesInfo',JSON.stringify(item));
            this.$router.push({path:'/melves/details'})
        }
    }
}
</script>

<style lang="scss" scoped>
.home{
    .header-banner{
        .img{
            width: 100%;
        }
    }
    .header{
        display: flex;
        padding: 10px;
        justify-content: space-around;
        .block-box{
            background: #3574a0;
            color: #fff;
            width: 30%;
            padding: 10px 0;
            border-radius: 10px;
            line-height: 1.5;
            box-shadow: 2px 3px 6px #a5a5a5;
            .num{
                font-size: 1.5rem;
                font-weight: bolder;
            }
        }
    }
    .body{
        padding: 10px;
        padding-bottom: 25%;
        .block-line-part{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 200px;
            .position-area{
                width: 49%;
                font-weight: bolder;
            }
            .line-area{
                width: 2%;
                height: 200px;
                background: #dedede;
                position: relative;
                &::after{
                    position: absolute;
                    top: 48%;
                    left: -10%;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #13a597;
                    content:' ';
                }
            }
            .inner-area{
                width: calc(49% - 40px);
                padding: 10px 20px;
                font-size:.85rem;
                .box-info{
                    border: 1px solid #ccc;
                    background: #a5a5a5;
                    color: #fff;
                    border-radius: 10px;
                    padding: 10px 10px;
                    text-align: left;
                    box-shadow: 2px 3px 6px #c1c1c1;
                    div{
                        padding-bottom: 4px;
                    }
                    span{
                        color: #353535;
                        font-size: .8rem;
                    }
                    .name{
                        color: #fff;
                        font-weight: bolder;
                    }
                    .time{
                        color: #353535;
                        font-size: .75rem;
                    }
                }
                
            }
        }
    }
    .block-inline-row{
        text-align: left;
        background-color: #a5a5a5;
        border-radius: 10px;
        color: #fff;
        font-size: .85rem;
        margin-bottom: 10px;
        .top{
            display: flex;
            justify-content: space-between;
            padding: 10px;
            font-weight: bolder;
            border-bottom: 1px solid #fff;
            color: #fff;
            font-size: .95rem;
        }
        .middle{
            padding: 10px;
            span{
                color: #353535;
            }
            div{
                padding-bottom: 5px;
            }
        }
        .time{
            color: #353535;
            text-align: right;
        }
        .bottom{
            padding: 10px;
            border-top: 1px solid #fff;
        }
    }

    .online{
        background: #13a597 !important;
    }
    .alarm{
        background: #e06868 !important;
    }
}
.road-tag-mild{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #e1e56a;
    display: block;
    margin-left: 4px;
}
.road-tag-serious{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #e06868;
    display: block;
    margin-left: 4px;
}
.flex-algin-center{
    display: flex;
    align-items: center;
}
</style>